<template>
  <b-modal
      :id="'glosa-reason-modal'"
      hide-header
      hide-footer
      centered
      size="xl"
      @show="onShow"
      @hidden="closeModal"
      modal-class="modal-custom"
      no-close-on-backdrop
  >
    <div class="modal-header">
      <div class="modal-title">
        <span>Observações</span>
      </div>
      <span class="icon-close">
        <Close class="stroke" @click="closeModal" />
      </span>
    </div>

    <div class="around-content mt-3 p-2">
      <div class="row mb-3">
        <div class="col-md-3">
          <label class="label-custom"><strong class="text-blue">Paciente:</strong> {{ guideItem?.tiss_guide?.patient?.name }}</label>
        </div>
        <div class="col-md-2">
          <label class="label-custom"><strong class="text-blue">Situação:</strong> {{ guideItem?.status }}</label>
        </div>
        <div class="col-md-2">
          <label class="label-custom"><strong class="text-blue">Guia:</strong> {{ guideItem?.tiss_guide?.provider_guide }}</label>
        </div>
        <div class="col-md-5">
          <label class="label-custom"><strong class="text-blue">Item:</strong> {{ guideItem?.item?.name }}</label>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-12">
          <b-form-group>
            <label for="glosa_reason">Motivo da glosa</label>
            <multiselect
                id="glosa_reason"
                autocomplete="off"
                v-model="selectedGlosaReason"
                :options="glosaReasonOptions"
                track-by="id"
                label="label"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                placeholder="Selecionar"
                class="with-border"
                ref="glosa_reason"
                :state="errors.glosa_reason"
                @search-change="getGlosaReasons"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors.glosa_reason">
              {{ errors.glosa_reason }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-12">
          <b-form-group>
            <label for="justification_appeal">Justificativa para recurso <span class="help">(opcional)</span></label>
            <b-form-input
                placeholder="Descrever"
                autocomplete="off"
                class="form-control"
                ref="justification_appeal"
                v-model="form.justification_appeal"
                :state="errors.justification_appeal ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.justification_appeal">
              {{ errors.justification_appeal }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <b-form-group>
            <label for="observation">Observação <span class="help">(opcional)</span></label>
            <b-form-textarea
                id="observation"
                v-model="form.observation"
                placeholder="Descrever"
                rows="3"
                max-rows="6"
            ></b-form-textarea>
            <b-form-invalid-feedback v-if="errors.observation">
              {{ errors.observation }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

    </div>

    <div class="wrapper-button text-right mt-5">
      <button class="btn cancel-button mr-3" @click="closeModal">
        Cancelar
      </button>
      <b-button variant="primary" size="lg" @click="saveForm">
        Atualizar
      </b-button>
    </div>

  </b-modal>

</template>

<script>
import '@/assets/scss/modal-custom.scss';
import { getCurrentClinic } from "@/utils/localStorageManager";

export default {
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down-select.svg'),
    Close: () => import('@/assets/icons/close.svg'),

  },
  data() {
    return {
      clinic: getCurrentClinic(),
      form: {},
      errors: {},
      selectedGlosaReason: null,
      glosaReasonOptions: [],
    }
  },
  props: {
    guideItem: {
      type: Object,
      default: () => ({})
    },
  },
  async mounted (){
    this.getGlosaReasons('');
  },
  watch: {
    guideItem: {
      handler(value) {
        if(value) {
          this.form.justification_appeal = value.justification_appeal;
          this.form.observation = value.observation;

          if (value.glosa_reason) {
            const {glosa_reason} = value;

            this.selectedGlosaReason = {
              label: `${glosa_reason?.code} - ${glosa_reason.description}`,
              id: glosa_reason.id
            };
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    // --------- Métodos de Controle do Modal ---------
    openModal(origin) {
      this.origin = origin;
      this.$bvModal.show('glosa-reason-modal');
    },
    onShow() {
      this.resetForm();
    },
    closeModal() {
      this.$bvModal.hide('glosa-reason-modal')
    },
    getGlosaReasons(query) {
      this.isLoading = true
      this.api.getGlosaReasons({ query: query })
          .then(res => {
            this.glosaReasonOptions = res.data.data.map(item => {
              return {
                ... item,
                label: `${item?.code} - ${item.description}`,
                id: item.id,
              }
            })
          })
          .catch(err => {
            this.$toast.error(err.message)
          }).finally(() => {
        this.isLoading = false
      })
    },
    // --------- Métodos de Gerenciamento do Formulário ---------
    resetForm() {
      this.form = {};
      this.errors = {};
      this.selectedGlosaReason = null;
    },
    isValidForm() {
      const errors = {};

      if(!this.selectedGlosaReason){
        errors.glosa_reason = "Campo Obrigatório";
      }

      this.errors = errors;

      return Object.keys(errors).length === 0;
    },
    async saveForm() {
      if (!this.isValidForm()) {
        this.$toast.error('Preencha todos os campos obrigatórios.')
        return
      }

      this.form.clinic_id = this.clinic.id;

      const isLoading = this.$loading.show()

      try {
        const payload = {
          ...this.form,
          'glosa_reason_id': this.selectedGlosaReason.id
        };

        await this.api.updateGlosaReason(this.guideItem?.id, payload).then(res => {
          this.$toast.success('Atualizado com sucesso!');
          this.$emit('updateGlosaReason');
          this.closeModal();
        });

      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
  },
};
</script>
<style lang="scss">
#glosa-reason-modal {
  .invalid-feedback{
    display: block;
  }
}
</style>